import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import Image from 'src/components/ghp-photo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I'm `}<strong parentName="p">{`Gus Hahn-Powell`}</strong>{`.`}</p>
    <Image mdxType="Image" />
    {/* ![GHP](/images/ghp.jpg) */}
    <h2 {...{
      "id": "academic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#academic",
        "aria-label": "academic permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Academic`}</h2>
    <p>{`I am an `}<a parentName="p" {...{
        "href": "https://linguistics.arizona.edu/faculty-0",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Assistant Professor in the Department of Linguistics at the University of Arizona`}</a>{` where I direct the `}<a parentName="p" {...{
        "href": "https://uazhlt.github.io/hlt-online/about",
        "target": "_self",
        "rel": "nofollow"
      }}>{`online MS in Human Language Technology (HLT)`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://uazhlt.github.io/nlp-cert/about",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Graduate Certificate Program in Natural Language Processing (NLP)`}</a>{`.  I also hold appointments in the `}<a parentName="p" {...{
        "href": "https://cogsci.arizona.edu/faculty",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Cognitive Science GIDP`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://css.arizona.edu/people",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Computational Social Science Graduate Certificate Program`}</a>{`.  `}</p>
    <p><a parentName="p" {...{
        "href": "/projects"
      }}>{`My research`}</a>{` centers around machine reading for scientific discovery.  In other words, I build and design intelligent systems to help researchers surmount the problem of information overload by scouring the vast body of scientific literature, analyzing findings, and synthesizing discoveries to generate novel hypotheses.`}</p>
    <h2 {...{
      "id": "industry",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#industry",
        "aria-label": "industry permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Industry`}</h2>
    <p>{`While a PhD student, I co-founded `}<a parentName="p" {...{
        "href": "https://lum.ai",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Lum AI`}</a>{` (pronounced `}<a parentName="p" {...{
        "href": "http://ipa-reader.xyz/?text=lu%3Am",
        "target": "_self",
        "rel": "nofollow"
      }}>{`/lu:m/`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      